import React, { FC } from 'react';
import styled from 'styled-components';

const StyledIntroduction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 70vh;
  font-size: 1.75em;
  font-weight: lighter;
  text-align: center;
`;

const StyledLinkSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 30vh;
  font-size: 1.15em;
  font-weight: lighter;
  text-align: center;
`;

const StyledHeader = styled.h1``;

const StyledIntroductionText = styled.p`
  margin-top: 0.08rem;
`;

const StyledAnchor = styled.a`
  color: #FFFFFF;
  text-decoration: none;
  margin: 0.5rem;
  :hover{
    text-decoration: underline;
  }
`;

const MainPage: FC = () => (
  <>
    <StyledIntroduction>
      <StyledHeader>
        Hi, I'm Braňo &#128075;
      </StyledHeader>
      <StyledIntroductionText>
        Software engineer with expertise in web development.
      </StyledIntroductionText>
    </StyledIntroduction>
    <StyledLinkSection>
      <StyledAnchor href={'https://www.linkedin.com/in/branislav-osif-7b11a31b6'}>LinkedIn</StyledAnchor>
      <StyledAnchor href={'https://github.com/brano-osif'}>GitHub</StyledAnchor>
    </StyledLinkSection>
  </>
);

export default MainPage;
